<script>
import SoAHeader from '@/components/PageHeader/SoAHeader'
import VisitDetail from '@/components/VisitDetail/VisitDetail'
import RequiredCRFBanner from '@/components/Banners/RequiredCRFBanner'
import GET_VISIT_QUERY from '@/graphql/visits/GetVisitQuery.graphql'
import GET_FORMS_PREVIEW_QUERY from '@/graphql/forms/GetFormsPreviewQuery.graphql'
import detectModule from '@/mixins/detectModule'
import AddFormsModal from '@/components/AddFormsModal/AddFormsModal'
import StartVisitModal from '@/components/StartVisitModal/StartVisitModal'
import { getProtocolVersion, getBlockingForms, getBlockingVisit } from '@/utils/visit'
import { FormStatus, CTMSPermission } from '@/utils/constants'
import { mapState } from 'vuex'
import { userHasStudyPermission } from '../../utils/user'
import { isParticipantExcluded } from '@/ppmi/participants'
import participant from '@/mixins/queries/participant'
import ShowSubstudiesModal from '../../components/ShowSubstudiesModal/ShowSubstudiesModal'
import visitWindows from '@/mixins/queries/visitWindows'
import getSubstudyVisits from '@/mixins/queries/substudyVisits'

export default {
  components: {
    ShowSubstudiesModal,
    SoAHeader,
    VisitDetail,
    AddFormsModal,
    RequiredCRFBanner,
    StartVisitModal
  },
  mixins: [
    detectModule,
    participant,
    visitWindows,
    getSubstudyVisits
  ],
  data () {
    return {
      searchString: '',
      addFormsModalVisible: false,
      showSubstudiesModalVisible: false,
      activeVisits: [],
      visitInstancesWithForms: [],
      displayStartVisitModal: false,
      formsLoaded: false,
      visitToStart: {}
    }
  },
  computed: {
    ...mapState(['isOnline']),

    // get an array of unadded forms to be used in the add forms modal
    // We allow any form to be added to the visit, whether it's optional or not. This is to account for schedule
    // changes between when the visit was started and when it was completed. If a form is now required, it will
    // be added as normal, then no longer be able to be removed.

    canNavigateForms() {
      return this.isEDCActive || userHasStudyPermission(
        this.currentUser,
        this.$route.params.studyId,
        CTMSPermission.VIEW_FORMS)
    },

    protocolVersion() {
      if (this.visit) {
        return getProtocolVersion(this.visit)
      }

      return ''
    },

    // TODO handle multiple visits
    consentForm() {
      const consentForm = this.visitData.formInstances.find(formInstance =>
        (formInstance.formVersion.behaviorType === 'informed_consent')
      )
      return consentForm || {}
    },

    /**
     * Retrieve all forms that block other forms across visits (A form that must be completed to "unlock" others).
     */
    requiredCRFsToContinue() {
      const requiredCRFsToContinue = this.visitInstancesWithForms.flatMap(visit => {
        const visitWindow = this.visitWindow.visits.find(v => v.visitInstance?.id === visit.visitInstance?.id)
        return getBlockingForms(visit, visitWindow)
      })
      return requiredCRFsToContinue
    },

    /**
     * Show the Required CRF banner if a requiredCRF is found and the consent banner is not currently displayed.
     */
    showRequiredCRFsBanner() {
      return this.isEDCActive && this.requiredCRFsToContinue.length
    },

    hasIncompleteForms() {
      return this.visitData.formInstances.some(formInstance => {
        if (formInstance.isOptional) {
          // if it is an optional form AND it is in progress, it is incomplete.
          return formInstance.status === FormStatus.IN_PROGRESS
        } else {
          // if it is a required form and it does not have a status of complete or skipped, it is incomplete
          return formInstance.status !== FormStatus.COMPLETE && formInstance.status !== FormStatus.NOT_COMPLETING
        }
      })
    },

    visitHasData() {
      return this.visitData.formInstances.some(formInstance => formInstance.status !== FormStatus.NOT_STARTED)
    },

    /**
     * Check if the visit is skippable.
     * If a visit has been started but no data is associated with it (progress = 0),
     * it can be marked as "Did Not Complete"
     * @returns {boolean}
     */
    displayDidNotCompleteOption() {
      return this.visitData.visitTemplate.isSkippable && !this.visitHasData
    },

    hasContextOptions() {
      return this.displayDidNotCompleteOption
    },

    getVisitsToDisplay() {
      if (isParticipantExcluded(this.participant)) {
        return this.visitWindow.visits.filter(visit => visit.visitInstance)
      } else {
        return this.visitWindow.visits
      }
    }
  },
  watch: {
    visitWindow(val) {
      this.mapWindowVisits(val)
    }
  },
  mounted() {
    this.$store.subscribeAction((action, state) => {
      if (action.type === 'refreshVisitWindow') {
        this.mapWindowVisits()
      }
    })
  },
  methods: {
    /**
     * Open the date picker to select a new visit start date.
     * Calls the openDatepicker method on the BfDatePicker component.
     * The selection will be picked up normally through the @change event.
     */
    pickNewStartDate() {
      this.$refs.startDatePicker.openDatepicker()
      if (this.$refs.startDateOptions) {
        this.$refs.startDateOptions.toggle()
      }
    },

    mapWindowVisits (val = this.visitWindow) {
      const participantId = this.$route.params.participantId
      Promise.all(
        this.visitWindow.visits.map(visit => {
          // A valid `visit.visitInstance` indiciates that the visit has either been started or
          // completed, thus containing all the necessary information to build the visit detail
          // otherwise retrieve forms for the the visit window to preview to user
          if (visit.visitInstance) {
            return this.$apollo.query({
              query: GET_VISIT_QUERY,
              variables: {
                visitInstanceId: visit.visitInstance.id
              }
            })
          } else {
            return this.$apollo.query({
              query: GET_FORMS_PREVIEW_QUERY,
              variables: {
                participantId,
                protocolVersionId: visit.visitTemplate?.protocolVersion?.id,
                scheduleVersionVisitId: visit.visitTemplate?.id
              }
            })
          }
        })
      ).then(response => {
        for (let ii = 0; ii < response.length; ii++) {
          if (response[ii].data.getVisitInstance) {
            // Valid `visitInstance` indicates that visit has started, completed or reopened
            const visitInstances = response.filter(Boolean).map(visitData => visitData.data.getVisitInstance)
              .filter(instance => instance !== undefined)

            for (const i in visitInstances) {
              const vi = visitInstances[i]
              const viToReplace = this.visitInstancesWithForms.find(v => v.visitTemplate.id === vi.visitTemplate.id)

              if (viToReplace) {
                // replace with a new visit instance if it changed
                this.visitInstancesWithForms = this.visitInstancesWithForms
                  .map(v => v.visitTemplate.id === vi.visitTemplate.id ? vi : v)
              } else {
                this.visitInstancesWithForms.push(vi)
              }
            }
          } else if (response[ii].data.getFormsPreviewForVisit) {
            // Mock up existing object required by components to render properly with exception to
            // the `formInstances` array, which contains a preview of the forms for said visit
            const formInstances = response[ii].data.getFormsPreviewForVisit.map(form => {
              return {
                id: form.id,
                position: form.position,
                requiredForms: [],
                status: FormStatus.NOT_STARTED,
                assessmentDate: null,
                reason: null,
                comment: null,
                isOptional: false,
                formVersion: {
                  ...form,
                  __typename: 'FormVersion'
                },
                __typename: 'FormInstance'
              }
            })

            // Find first form by `formVersion.position`
            const firstFormInstance = formInstances.find(formInstance => formInstance.formVersion.position === 0)
            if (firstFormInstance) {
              for (let ii = 0; ii < formInstances.length; ii++) {
                if (formInstances[ii].id !== firstFormInstance.id) {
                  formInstances[ii].requiredForms.push(firstFormInstance.id)
                }
              }
            }

            const vi = {
              visitTemplate: {
                ...this.visitWindow.visits[ii].visitTemplate
              },
              visitInstance: null,
              formInstances,
              formVersions: [],
              visitTemplateForms: [],
              // NOT part of reponse object - injected here to indicate that this object is forms preview
              isFormPreview: true,
              __typename: 'VisitInstanceWithForms'
            }

            if (this.visitInstancesWithForms.find(v => v.visitTemplate.id === vi.visitTemplate.id)) {
              // replace with a new visit instance if it changed
              this.visitInstancesWithForms = this.visitInstancesWithForms
                .map(v => v.visitTemplate.id === vi.visitTemplate.id ? vi : v)
            } else {
              this.visitInstancesWithForms.push(vi)
            }
          }
        }
      }).finally(() => {
        // Not sure how/when `undefined` is getting pushed onto array but filter it out!!!
        this.visitInstancesWithForms = this.visitInstancesWithForms.filter(viwf => viwf !== undefined)
        this.formsLoaded = true
      })

      // also, set each accordion to open by default
      this.activeVisits = val.visits.map(visit => visit.visitTemplate.code)
    },

    handleCompleteVisitClick() {
      if (!this.isOnline) { return } // cancel action if offline
      if (this.hasIncompleteForms) {
        this.confirmApproveVisitModalVisible = true
      } else {
        this.approveVisitModalVisible = true
      }
    },

    handleSkipVisitClose() {
      this.$router.push({
        name: this.isEDCActive ? 'visitSchedule' : 'participantDetails',
        params: { ...this.$route.params }
      })
    },

    getBlockingVisitName(visit) {
      const blockingVisit = getBlockingVisit(visit, this.visitWindow)

      return blockingVisit ? blockingVisit.visitTemplate.name : null
    },

    handleStartVisitClick(visit) {
      this.visitToStart = visit
      this.displayStartVisitModal = true
    },

    handleSubstudyModalCloseEvent() {
      this.$apollo.queries.visitWindow.refetch().then(response => {
        this.$root.$emit('updateSubstudyVisitCount', response.data.getParticipantVisitWindowV2.visits)
      })
      this.showSubstudiesModalVisible = false
    }
  }
}
</script>

<template>
  <div class="schedule-of-activities">
    <SoAHeader
      v-if="formsLoaded"
      :visit-window="visitWindow"
      :loading="$apollo.queries.visitWindow.loading"
      :visits="visitInstancesWithForms"
      @search-change="searchString = $event"
      @add-forms="addFormsModalVisible = true"
      @show-substudies="showSubstudiesModalVisible = true"
    />

    <RequiredCRFBanner
      v-if="showRequiredCRFsBanner"
      :required-forms="requiredCRFsToContinue"
    />

    <el-collapse
      v-if="visitWindow"
      v-model="activeVisits"
    >
      <VisitDetail
        v-for="visit in getVisitsToDisplay"
        :key="visit.visitTemplate.code"
        v-loading="!formsLoaded"
        :waiting-for-visit="getBlockingVisitName(visit)"
        :search-string="searchString"
        :visit-data="visitInstancesWithForms
          .filter(vis => vis !== undefined)
          .find(vis => vis.visitTemplate.id === visit.visitTemplate.id) || {}"
        :visit="visit"
        @start-visit="handleStartVisitClick"
      />
    </el-collapse>

    <AddFormsModal
      v-if="addFormsModalVisible && visitWindow"
      :visits="visitInstancesWithForms"
      :visit-window-visits="visitWindow.visits"
      @close="addFormsModalVisible = false"
    />
    <ShowSubstudiesModal
      v-if="showSubstudiesModalVisible"
      :visits="visitInstancesWithForms"
      @close="handleSubstudyModalCloseEvent"
    />
    <StartVisitModal
      :visit="visitToStart"
      :display-modal="displayStartVisitModal"
      @close="displayStartVisitModal = false"
    />
  </div>
</template>

<style lang="scss">
  .schedule-of-activities {
    .el-collapse {
      border: none;
    }
  }
</style>
