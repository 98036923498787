import GET_PARTICIPANT_SUBSTUDY_VISITS from '@/graphql/participants/getParticipantVisitWindowInfos.graphql'

export default {
  methods: {
    async getSubstudyVisits(
      participantId,
      visitWindowId
    ) {
      const variables = {
        participantId,
        visitWindowId
      }

      return (await this.$apollo.query({
        query: GET_PARTICIPANT_SUBSTUDY_VISITS,
        variables
      })).data.getParticipantSubstudyVisits
    }
  }
}
