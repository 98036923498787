<script>
import PageHeader from './PageHeader'
import SkeletonBox from '@/components/SkeletonBox/SkeletonBox'
import SearchBox from '@/components/SearchBox/SearchBox'
import detectModule from '@/mixins/detectModule'
import participant from '@/mixins/queries/participant'
import sites from '@/mixins/queries/sites'
import {
  getProtocolVersion,
  isVisitWindowInProgress,
  getVisitCompletionPercent,
  getVisitWindowLabel
} from '@/utils/visit'
import { isVisitWindowReopened } from '../../utils/visit'
import getSubstudyVisits from '@/mixins/queries/substudyVisits'
import substudies from '@/mixins/queries/substudies'

export default {
  components: {
    PageHeader,
    SkeletonBox,
    SearchBox
  },
  mixins: [
    detectModule,
    participant,
    sites,
    getSubstudyVisits,
    substudies
  ],
  props: {
    visit: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: true
    },
    visitWindow: {
      type: Object,
      default: null
    },
    visits: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      visibleTooltip: false,
      availableSubstudies: 0
    }
  },
  computed: {
    visitWindowLabel() {
      if (this.visitWindow) {
        return getVisitWindowLabel(this.visitWindow, this.participant.protocols)
      }

      return ''
    },
    /**
     * A router object to send a user back to the participant's Visit Schedule/Participant Detail screen.
     * @returns {object} - a router link object
     */
    backToParticipant() {
      return {
        text: this.fullNameOrPPMIPlusId,
        route: {
          name: this.isEDCActive ? 'visitSchedule' : 'participantDetails',
          params: { ...this.$route.params }
        }
      }
    },

    allFormInstances() {
      return this.visits.flatMap(visitInstance => visitInstance?.formInstances)
    },

    /**
     * Get the complete % for this visit.
     */
    percentComplete() {
      return this.allFormInstances.length ? getVisitCompletionPercent(this.allFormInstances) : 0
    },

    /**
     * Get the current protocol version.
     * @returns {string}
     */
    protocolVersion() {
      if (this.visitWindow) {
        return getProtocolVersion(this.visitWindow.visits[0])
      }

      return ''
    },

    showActions() {
      return this.isEDCActive &&
          this.visitWindow &&
          (isVisitWindowInProgress(this.visitWindow) || isVisitWindowReopened(this.visitWindow))
    }
  },
  updated() {
    if (this.isSubstudiesSupported) {
      this.getSubstudyVisits(
        this.$route.params.participantId,
        this.$route.params.visitWindowId
      ).then(data => {
        this.availableSubstudies = data.filter(item => !item.isActive).length
        this.visibleTooltip = this.availableSubstudies > 0
      })

      this.$nextTick(function () {
        // Code that will run only after the entire view has been rendered (from VUE documentation)
        this.$root.$on('updateSubstudyVisitCount', function(val) {
          this.visits = val
          this.$forceUpdate()
        })
      })
    }
  }
}
</script>

<template>
  <PageHeader
    class="soa-header"
    :title="visitWindowLabel"
    :back-link="backToParticipant"
  >
    <template v-slot:title>
      <h1 class="visit-name">
        <SkeletonBox
          v-if="loading"
          width="12rem"
        />
        <span v-else>{{ visitWindowLabel }}</span>
      </h1>
      <p
        v-if="isEDCActive"
        class="visit-info"
      >
        <SkeletonBox
          v-if="loading"
          width="15rem"
        />
        <span v-else>
          {{ percentComplete }}% Complete • Protocol Version {{ protocolVersion }}
        </span>
      </p>
    </template>
    <template v-if="isCTMSActive">
      <dl class="participant-details">
        <div class="participant-details__field">
          <dt>Progress</dt>
          <dd>
            <SkeletonBox v-if="loading" />
            <span v-else>{{ percentComplete }}% Complete</span>
          </dd>
        </div>
        <div class="participant-details__field">
          <dt>Protocol</dt>
          <dd>
            <SkeletonBox v-if="loading" />
            <span v-else>Protocol Version {{ protocolVersion }}</span>
          </dd>
        </div>
        <div class="participant-details__field">
          <dt>Site</dt>
          <dd>
            <SkeletonBox v-if="loading" />
            <span v-else>{{ getSiteName(participant.siteId) }}</span>
          </dd>
        </div>
      </dl>
    </template>
    <template v-slot:actions>
      <SearchBox
        placeholder="Find a CRF"
        @input="$emit('search-change', $event)"
      />
      <div class="bottom" v-if="isSubstudiesSupported">
        <el-tooltip
          v-model="visibleTooltip"
          class="tooltip-info"
          :visible-arrow="true"
          effect="info"
          :manual="true"
          :content="`${availableSubstudies} ${availableSubstudies > 1 ? 'sub-studies' : 'sub-study'} available`"
          placement="bottom"
        >
          <el-button
            class="add-forms-button"
            :class="{'alert': visibleTooltip}"
            type="menu"
            @click="$emit('show-substudies')"
          >
            <SvgIcon
              name="book"
              width="1rem"
              height="1rem"
            />
          </el-button>
        </el-tooltip>
      </div>

      <el-button
        v-if="showActions"
        class="add-forms-button"
        type="menu"
        @click="$emit('add-forms')"
      >
        <SvgIcon
          name="add"
          width="1rem"
          height="1rem"
        />
      </el-button>
    </template>
  </PageHeader>
</template>

<style lang="scss">
  .bottom {
    clear: both;
    text-align: center;
    .tooltip-info {
      background-color: $warning;
    }
  }
  .header.soa-header {
    padding: 1rem;
    padding-top: .5rem;
    border-bottom: none;
    margin: -1.5rem;
    margin-bottom: 1.5rem;
    background-color: $white-matter;
    // sticky position header
    position: sticky;
    top: -1.5rem; // reset from padding wrap
    @include elevate(sm);
    border-radius: 0;

    .visit-name {
      @include text-style('title', 'large', 'bold');
    }

    &__title-wrap {
      align-items: center;
    }

    .visit-info {
      margin: 0;
      color: $black;
      @include text-style('interface', 'small', 'normal');
    }

    .back-link {
      margin-left: -.5rem;
    }

    .search-box input {
      max-width: 12rem;
      transition: all .3s;

      &:focus,
      &:active {
        max-width: 17rem;
      }
    }
  }

  #app .add-forms-button {
    span {
      display: inline;
    }
    &.alert {
      border-color: $warning;
      background-color: $warning-bg;
    }
  }
</style>
