<template>
  <Modal
    v-loading="$apollo.queries.substudyVisitsQuery.loading"
    class="add-forms-modal"
    title="Select sub-study to add to visit"
    fix-header-and-footer
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div>
        <SearchBox
          v-model="searchString"
          class="add-forms-modal__search-box"
          placeholder="Search"
        />
      </div>
      <CRFRadioGroup
        v-if="substudyVisits.filter(v => !v.isActive).length"
        v-model="selectedStudy"
        class="button-group"
        :question="{options: filteredOptions}"
        :hide-circles="true"
      />
      <EmptyState
        v-else
        image="form"
        title="No Sub-study Visits Available"
        subtitle="There aren’t any sub-study visits available to add to this visit window"
        image-above-text
      />
    </template>
    <template v-slot:actions>
      <el-button
        :disabled="!options.length || !selectedStudy"
        type="primary"
        @click="addFormToVisitWindow"
      >
        Add to Visit Window
      </el-button>
      <el-button
        type="outline"
        @click="$emit('close')"
      >
        Cancel
      </el-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal/Modal'
import SearchBox from '@/components/SearchBox/SearchBox'
import EmptyState from '@/components/EmptyState/EmptyState'
import addOptionalForms from '@/mixins/addOptionalForms'
import CRFRadioGroup from '../CRFRadioGroup/CRFRadioGroup'
import GET_PARTICIPANT_SUBSTUDY_VISITS from '@/graphql/participants/getParticipantVisitWindowInfos.graphql'
import ADD_SUBSTUDY_TO_VISIT_WINDOW from '@/graphql/studies/AddSubstudyVisitToVisitWindow.graphql'
import visitWindows from '@/mixins/queries/visitWindows'
import { logError } from '@/utils/logging'

export default {
  components: {
    CRFRadioGroup,
    Modal,
    SearchBox,
    EmptyState
  },
  mixins: [ addOptionalForms, visitWindows ],
  props: {
    visits: {
      type: Array,
      default: () => []
    }
  },
  apollo: {
    substudyVisitsQuery() {
      return {
        query: GET_PARTICIPANT_SUBSTUDY_VISITS,
        variables: {
          participantId: this.$route.params.participantId,
          visitWindowId: this.$route.params.visitWindowId
        },
        update: (data) => {
          this.substudyVisits = data.getParticipantSubstudyVisits
          this.options = data.getParticipantSubstudyVisits
            .filter(item => !item.isActive)
            .sort(function(a, b) {
              return a.position - b.position
            })
            .map((item, index) => {
              if (!item.isActive) {
                return {
                  position: index,
                  value: item.visitId,
                  displayValue: `${item.protocolName}: ${item.visitTemplate.name}`
                }
              }
            })
          this.filteredOptions = this.options
        },
        error (error) {
          logError(error)
        }
      }
    }
  },
  data() {
    return {
      searchString: '',
      selectedStudy: '',
      substudyVisits: [],
      options: [],
      filteredOptions: []

    }
  },
  watch: {
    searchString(val) {
      this.filteredOptions = this.options.filter((item) => {
        return item.displayValue.trim().toLowerCase().includes(val.trim().toLowerCase())
      })
    }
  },
  methods: {
    addFormToVisitWindow () {
      const selectedVisit = this.substudyVisits.find(item => item.visitId === this.selectedStudy)
      this.$apollo.mutate({
        mutation: ADD_SUBSTUDY_TO_VISIT_WINDOW,
        variables: {
          protocolId: selectedVisit.protocolId,
          visitWindowId: this.$route.params.visitWindowId,
          visitId: selectedVisit.visitId
        }
      }).catch((e) => {
        logError(e, 'add substudy to visit window mutation')
      }).finally(() => {
        this.$emit('close')
      })
    }
  }
}
</script>
<style lang="scss">
.add-forms-modal {
  &__search-box {
    margin-bottom: 1rem;
  }
}
</style>
