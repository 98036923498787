
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FormPreview"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FormPreview"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"version"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"code"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"category"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"position"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"displayType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"behaviorType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"instructions"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"footer"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"requiredUserTypes"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":175}};
    doc.loc.source = {"body":"fragment FormPreview on FormPreview {\n  id,\n  version,\n  title,\n  code,\n  category,\n  position,\n  displayType,\n  behaviorType,\n  instructions,\n  footer,\n  requiredUserTypes\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
