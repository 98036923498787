import REOPEN_VISIT_INSTANCE from '@/graphql/visits/ReopenVisitMutation.graphql'
export default {
  methods: {
    /**
       * Reopen Visit Instance
       * @param visitInstanceId
       * @param reason
       * @param notes
       * @returns {Promise<FetchResult<any>>}
       */

    reopenVisitInstance(
      visitInstanceId,
      reason,
      notes
    ) {
      return this.$apollo.mutate({
        mutation: REOPEN_VISIT_INSTANCE,
        variables: {
          visitInstanceId,
          reason,
          notes
        }
      })
    }
  }
}
