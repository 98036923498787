<template>
  <Modal
    @close="$emit('close')"
  >
    <template v-slot:title>
      Visit cannot be reopened
    </template>
    <template v-slot:content>
      <p>
        A visit in the window {{ reopenedWindow.startDate | formatDate }}
        - {{ reopenedWindow.endDate | formatDate }}  is open. <br>
        Only one previous visit can be reopened at a time.
      </p>
    </template>
    <template v-slot:actions>
      <el-button
        type="primary"
        @click="$emit('close')"
      >
        Okay
      </el-button>
      <el-button
        type="outline"
        @click="goToVisitWindow(reopenedVisit)"
      >
        View Open Visit
      </el-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal/Modal'

export default {
  components: {
    Modal
  },
  props: {
    reopenedVisit: {
      type: Object,
      required: true
    },
    reopenedWindow: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    }
  },
  methods: {
    goToVisitWindow() {
      this.$router.replace({
        name: 'scheduleOfActivities',
        params: {
          ...this.$route.params,
          visitTemplateId: this.reopenedVisit.visitTemplate.id
        }
      }).then(s => {
        // HACK: since "replace" does not force the "watch" event on "ScheduleOfActivities.vue" page since this scenario
        // has the modal on the SoA page, we must:
        //    - call the "replace" to update the route parameters
        //    - once that is successful, we will then invoke the "go" method to reload the SoA screen thus updating with
        //      correct data
        this.$router.go()
      }).catch(e => {
        console.log(e)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.complete-visit-modal {
  /deep/ .field-wrap {
    width: 64%;

    &:first-of-type {
      width: 100%;
      padding-right: 2rem;
    }
  }
  .datepicker {
    margin-top: 1rem;
  }
}
</style>
