<template>
  <Modal
    @close="$emit('close')"
  >
    <template v-slot:title>
      Reopen previous visit
    </template>
    <template v-slot:content>
      <p class="subtext">
        To continue, select a reason and enter your password.
      </p>
      <ValidationProvider
        v-slot="{ errors }"
        rules="required"
      >
        <BfSelect
          v-model="reasonSelected"
          label="Reason"
          placeholder="Select Reason"
          popper-class="visit-not-complete-reason-dropdown"
        >
          <el-option
            v-for="(item, index) in reopenReasons"
            :key="index"
            :label="item"
            :value="item"
          />
        </BfSelect>
        <BfInput
          v-model="notes"
          type="text"
          label="Notes"
        />
        <BfInput
          v-model="password"
          :error="passwordError || errors[0]"
          type="password"
          label="Password"
          @input="passwordError = null"
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        rules="required"
      />
    </template>
    <template v-slot:actions>
      <el-button
        type="primary"
        @click="reopenVisit(visit)"
      >
        Continue
      </el-button>
      <el-button
        type="outline"
        @click="$emit('close')"
      >
        Cancel
      </el-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal/Modal'
import BfInput from '@/components/BfInput/BfInput'
import BfSelect from '@/components/BfSelect/BfSelect'
import { visitWindowReopenReasons } from '@/utils/constants'
import reopenVisitInstance from '@/mixins/mutations/reopenVisitInstance'
import Auth from '@aws-amplify/auth'
import GET_USER_QUERY from '@/graphql/users/GetCurrentUserQuery.graphql'
import { logError } from '@/utils/logging'
import { propEq } from 'ramda'

export default {
  components: {
    Modal,
    BfInput,
    BfSelect
  },
  mixins: [
    reopenVisitInstance
  ],
  props: {
    visit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      password: '',
      passwordError: null,
      reasonSelected: null,
      notes: '',
      reopenReasons: [...visitWindowReopenReasons],
      visitStartDate: ''
    }
  },
  apollo: {
    userEmail: {
      query: GET_USER_QUERY,
      update: data => data.getUser.emailAddress,
      error (error) {
        logError(error, 'CompleteVisitModal.vue user query')
      }
    }
  },
  methods: {
    async reopenVisit({ visitInstance }) {
      try {
        await Auth.signIn(this.userEmail, this.password)
        await this.reopenVisitInstance(visitInstance.id, this.reasonSelected, this.notes)
        this.$emit('is-visit-reopened')
      } catch (error) {
        if (propEq('code', 'NotAuthorizedException', error)) {
          this.passwordError = 'Incorrect Password.'
        } else {
          logError(error, 'CompleteVisitModal.vue complete visit mutation')
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .form-field {
    margin-top: 1rem;
  }
  .subtext {
    font-weight: 500;
  }
</style>
