<script>
import BfAlert from '@/components/BfAlert/BfAlert'
import ContextMenu from '@/components/ContextMenu/ContextMenu'
import SkeletonBox from '@/components/SkeletonBox/SkeletonBox'
import Dropdown from '@/components/Dropdown/Dropdown'
import BfDatePicker from '@/components/BfDatePicker/BfDatePicker'
import CRFList from '@/components/CRFList/CRFList'
import Modal from '@/components/Modal/Modal'
import SkipVisitModal from '@/components/SkipVisitModal/SkipVisitModal'
import CompleteVisitModal from '@/components/CompleteVisitModal/CompleteVisitModal'
import detectModule from '@/mixins/detectModule'
import updateVisitInstance from '@/mixins/mutations/updateVisitInstance'
import deleteVisitInstance from '@/mixins/mutations/deleteVisitInstance'
import { isVisitInstanceInProgress,
  getVisitInstanceDisplayDate,
  getVisitLabel,
  isVisitIntanceComplete
} from '@/utils/visit'
import { userHasStudyPermission } from '@/utils/user'
import { FormStatus, CTMSPermission, VisitType } from '@/utils/constants'
import { mapState } from 'vuex'
import visitInstanceStates from '@/mixins/visitInstanceStates'
import StatusPill from '@/components/StatusPill/StatusPill'
import RestrictedReopenModal from '../RestrictedReopenModal/RestrictedReopenModal'
import ReopenPreviousVisitModal from '../ReopenPreviousVisitModal/ReopenPreviousVisitModal'
import { VisitStatus } from '../../utils/constants'
import visitWindows from '@/mixins/queries/visitWindows'
import { getReopenedVisitWindow } from '../../utils/visit'
import ConfirmStartVisitModal from '../ConfirmStartVisitModal/ConfirmStartVisitModal'
import substudyVisits from '@/mixins/queries/substudyVisits'
import REMOVE_SUBSTUDY_FROM_WINDOW from '@/graphql/studies/RemoveSubstudyVisitFromVisitWindow.graphql'

export default {
  components: {
    ReopenPreviousVisitModal,
    RestrictedReopenModal,
    ContextMenu,
    SkeletonBox,
    Dropdown,
    BfDatePicker,
    CRFList,
    Modal,
    CompleteVisitModal,
    SkipVisitModal,
    BfAlert,
    StatusPill,
    ConfirmStartVisitModal
  },
  mixins: [
    detectModule,
    updateVisitInstance,
    deleteVisitInstance,
    visitInstanceStates,
    visitWindows,
    substudyVisits
  ],
  props: {
    currentUser: {
      type: Object,
      default: () => {
      }
    },
    searchString: {
      type: String,
      default: ''
    },
    visitData: {
      type: Object,
      default: () => {}
    },
    visit: {
      type: Object,
      default: null
    },
    waitingForVisit: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      updatedStartDate: '',
      approveVisitModalVisible: false,
      confirmApproveVisitModalVisible: false,
      displayDidNotCompleteVisitModal: false,
      displayRestrictedReopenModal: false,
      displayReopenPreviousVisitModal: false,
      displayConfirmStartVisitModal: false
    }
  },
  computed: {
    ...mapState(['isOnline']),
    isReopenedVisitInstance () {
      return this.visit?.visitInstance?.status === VisitStatus.REOPENED
    },
    visitName() {
      return getVisitLabel(this.visit)
    },
    isVisitInstanceComplete () {
      return this.visit?.visitInstance?.status === VisitStatus.COMPLETE
    },
    isVisitInstanceNotStarted () {
      return !this.visit.visitInstance && this.visit.isSubstudy
    },
    showStartVisit() {
      return this.isEDCActive &&
          !this.visitData.visitInstance &&
          !this.visit.visitInstance
    },

    showActions() {
      return this.isEDCActive && (isVisitInstanceInProgress(this.visit.visitInstance) ||
          this.visit?.visitInstance?.status === VisitStatus.REOPENED)
    },

    canNavigateForms() {
      return this.isEDCActive ||
          userHasStudyPermission(
            this.currentUser,
            this.$route.params.studyId,
            CTMSPermission.VIEW_FORMS
          )
    },

    /**
     * Get the display date for the current visit.
     * This will display the start date if the visit is not completed.
     * Will display the completed date after a visit has been completed.
     * @returns {string} - a date string of a status label + "visit date"
     */
    visitStartDate() {
      // ensure a visit instance exists.
      if (!this.visitData.visitInstance || !this.visitData.visitInstance.startDate) {
        return 'Not Started'
      }
      const statusLabel = isVisitInstanceInProgress(this.visitData.visitInstance) ||
      this.visitData?.visitInstance?.status === VisitStatus.REOPENED
        ? 'Started '
        : 'Completed '
      const dateDisplay = getVisitInstanceDisplayDate(this.visitData.visitInstance)
      return statusLabel.concat(dateDisplay)
    },

    canEditStartDate() {
      return this.isEDCActive && (isVisitInstanceInProgress(this.visitData.visitInstance) ||
          this.visitData?.visitInstance?.status === VisitStatus.REOPENED)
    },

    canClearStartDate() {
      return this.canEditStartDate && !this.visitHasData && this.visitData.visitInstance.startDate
    },

    hasIncompleteForms() {
      return this.visitData.formInstances.some(formInstance => {
        if (formInstance.isOptional) {
          // if it is an optional form AND it is in progress, it is incomplete.
          return formInstance.status === FormStatus.IN_PROGRESS
        } else {
          // if it is a required form and it does not have a status of complete or skipped, it is incomplete
          return formInstance.status !== FormStatus.COMPLETE && formInstance.status !== FormStatus.NOT_COMPLETING
        }
      })
    },

    visitHasData() {
      return this.visitData?.formInstances?.some(formInstance => formInstance.status !== FormStatus.NOT_STARTED)
    },

    /**
     * Check if the visit is skippable.
     * If a visit has been started but no data is associated with it (progress = 0),
     * it can be marked as "Did Not Complete"
     * @returns {boolean}
     */
    displayDidNotCompleteOption() {
      return this.visit?.visitTemplate?.isSkippable &&
          !this.visitHasData && this.visit?.visitInstance?.status !== VisitStatus.NOT_COMPLETING
    },

    hasContextOptions() {
      return this.displayDidNotCompleteOption ||
          this.isVisitInstanceComplete ||
          this.isVisitInstanceNotStarted
    },
    reopenedClass() {
      return this.isReopenedVisitInstance && this.isQueryResolutionSupported
    },
    reopenedVisitWindowInstance() {
      const window = getReopenedVisitWindow(this.allVisitWindows)
      return {
        window,
        visit: window ? window.visits.find(el => el.visitInstance.status === VisitStatus.REOPENED) : null
      }
    }
  },
  methods: {
    isVisitInstanceInProgress,
    isVisitIntanceComplete,
    handleClickReopenVisit() {
      if (this.isQueryResolutionSupported) {
        if (this.reopenedVisitWindowInstance.visit) {
          this.displayRestrictedReopenModal = true
          return
        }
        if (isVisitIntanceComplete(this.visit.visitInstance)) {
          this.displayReopenPreviousVisitModal = true
        }
      }
    },
    handleReopenedVisit () {
      this.displayReopenPreviousVisitModal = false

      // TODO: need to be refactored
      this.$router.go(this.$router.history?.current)
    },
    handleClickStartVisit () {
      if (this.visit.visitTemplate.visitType === VisitType.SCREENING &&
        this.visit.visitInstance === null) {
        this.displayConfirmStartVisitModal = true
      } else {
        this.$emit('start-visit', this.visit)
      }
    },
    handleClickRemoveFromVisit () {
      return this.$apollo.mutate({
        mutation: REMOVE_SUBSTUDY_FROM_WINDOW,
        variables: {
          visitId: this.visit.visitId,
          visitWindowId: this.$route.params.visitWindowId
        }
      }).then(() => {
        this.$apollo.queries.visitWindow.refetch().then(response => {
          this.$root.$emit('updateSubstudyVisitCount', response.data.getParticipantVisitWindowV2.visits)
        })
      }).catch((e) => {
        console.log(e)
      })
    },
    /**
     * Open the date picker to select a new visit start date.
     * Calls the openDatepicker method on the BfDatePicker component.
     * The selection will be picked up normally through the @change event.
     */
    pickNewStartDate() {
      this.$refs.startDatePicker.openDatepicker()
      if (this.$refs.startDateOptions) {
        this.$refs.startDateOptions.toggle()
      }
    },

    handleCompleteVisitClick() {
      if (!this.isOnline) {
        return
      } // cancel action if offline
      if (this.hasIncompleteForms) {
        this.confirmApproveVisitModalVisible = true
      } else {
        this.approveVisitModalVisible = true
      }
    },

    handleSkipVisitClose() {
      this.$router.push({
        name: this.isEDCActive ? 'visitSchedule' : 'participantDetails',
        params: { ...this.$route.params }
      })
    }
  }
}
</script>

<template>
  <div
    v-loading="$apollo.queries.visitWindow.loading"
    class="visit-detail"
    :class="{'reopened': reopenedClass }"
  >
    <el-collapse-item
      class="visit-detail__dropdown"
      :name="visit.visitTemplate.code"
    >
      <template v-slot:title>
        <div
          class="forms-header"
          @click.self.stop
        >
          <div
            class="visit-info"
          >
            <h2>
              {{ visitName }}
            </h2>
            <SkeletonBox
              v-if="updatingVisitInstance"
              width="15rem"
            />
            <p
              v-else
              class="visit-date"
              :class="canEditStartDate && 'editable'"
              @click.stop
            >
              <span>{{ visitStartDate }}</span>
              <Dropdown
                v-if="canClearStartDate"
                ref="startDateOptions"
                title="Edit Start Date"
                class="start-date-options"
                :close-on-click-outside="true"
              >
                <el-dropdown-item @click.native="pickNewStartDate">
                  Change Start Date
                </el-dropdown-item>
                <!-- clearVisitStartDate comes from the deleteVisitInstance mutation -->
                <el-dropdown-item @click.native="clearVisitStartDate(visitData.visitInstance)">
                  Clear Start Date
                </el-dropdown-item>
              </Dropdown>

              <el-button
                v-else-if="canEditStartDate"
                type="ghost"
                @click.stop="pickNewStartDate"
              >
                Edit Start Date
              </el-button>
              <BfDatePicker
                v-if="canEditStartDate"
                ref="startDatePicker"
                v-model="updatedStartDate"
                class="start-date-picker"
                popper-class="start-date-popper"
                @change="newValue => modifyVisitStartDate(visitData.visitInstance, newValue)"
              />
              <br>
              <StatusPill
                v-if="isReopenedVisitInstance && isQueryResolutionSupported"
                status="reopened"
                :text="isReopenedVisitTimeLabel(visit)"
              />
            </p>
          </div>

          <div
            class="visit-actions"
            @click.stop
          >
            <el-button
              v-if="showStartVisit"
              type="primary"
              :disabled="waitingForVisit !== '' && waitingForVisit != null"
              @click.native="handleClickStartVisit"
            >
              Start Visit
            </el-button>
            <el-button
              v-if="isReopenedVisitInstance && isQueryResolutionSupported"
              type="primary"
              :disabled="!isOnline"
              @click.native="handleCompleteVisitClick"
            >
              Mark as Complete
            </el-button>
            <el-button
              v-if="isVisitInstanceComplete"
              type="highlighted"
              :disabled="isVisitInstanceComplete"
              @click.native="handleCompleteVisitClick"
            >
              Complete Visit
              <span>✓</span>
            </el-button>
            <el-button
              v-if="isVisitInstanceInProgress(visit.visitInstance)"
              type="primary"
              :disabled="!isOnline"
              @click.native="handleCompleteVisitClick"
            >
              Complete Visit
            </el-button>
            <ContextMenu v-if="hasContextOptions">
              <el-dropdown-item
                v-if="displayDidNotCompleteOption"
                @click.native="displayDidNotCompleteVisitModal = true"
              >
                Did Not Complete
              </el-dropdown-item>
              <el-dropdown-item
                v-if="isQueryResolutionSupported && isVisitInstanceComplete"
                @click.native="handleClickReopenVisit"
              >
                Reopen Visit
              </el-dropdown-item>
              <el-dropdown-item
                v-if="isVisitInstanceNotStarted"
                @click.native="handleClickRemoveFromVisit"
              >
                Remove from Visit Window
              </el-dropdown-item>
            </ContextMenu>
          </div>
        </div>
      </template>
      <div
        class="visit-forms"
        :class="{'reopened': reopenedClass }"
      >
        <BfAlert v-if="waitingForVisit">
          Available once all assessments within {{ waitingForVisit }} are complete
        </BfAlert>
        <CRFList
          v-else
          :form-versions="visitData.formVersions || []"
          :form-instances="visitData.formInstances || []"
          :can-navigate-forms="canNavigateForms"
          :can-modify-forms="showActions"
          :show-search-bar="false"
          :visit-instance-id="visitData.visitInstance && visitData.visitInstance.id"
          :visit-status="visitData.visitInstance && visitData.visitInstance.status"
          :external-search-string="searchString"
          :loading="false"
          :visit-template-name="visit.visitTemplate.name"
          :is-form-preview="visitData.isFormPreview || false"
          :visit="visit"
        />
      </div>
    </el-collapse-item>

    <Modal
      v-if="confirmApproveVisitModalVisible"
      @close="confirmApproveVisitModalVisible = false"
    >
      <template v-slot:title>
        Visit has incomplete forms
      </template>
      <template v-slot:content>
        <p>
          All forms must be completed before this visit can be completed.
        </p>
      </template>
      <template v-slot:actions>
        <el-button
          type="primary"
          @click="confirmApproveVisitModalVisible = false"
        >
          Return to forms
        </el-button>
      </template>
    </Modal>
    <SkipVisitModal
      v-if="displayDidNotCompleteVisitModal"
      :visit="visit"
      @close="handleSkipVisitClose"
    />

    <CompleteVisitModal
      v-if="approveVisitModalVisible"
      :visit="visit"
      @close="approveVisitModalVisible = false"
    />

    <RestrictedReopenModal
      v-if="displayRestrictedReopenModal"
      :reopened-visit="reopenedVisitWindowInstance.visit"
      :reopened-window="reopenedVisitWindowInstance.window"
      @close="displayRestrictedReopenModal = false"
    />

    <ReopenPreviousVisitModal
      v-if="displayReopenPreviousVisitModal"
      :visit="visit"
      @is-visit-reopened="handleReopenedVisit"
      @close="displayReopenPreviousVisitModal = false"
    />

    <ConfirmStartVisitModal
      v-if="displayConfirmStartVisitModal"
      @start-visit-confirmed="$emit('start-visit', visit)"
      @close="displayConfirmStartVisitModal = false"
    />
  </div>
</template>

<style lang="scss">

  #app {
    div[role="tab"] .el-collapse-item__header:hover {
      color: $black;
      fill: $black;
    }
  }
  .visit-detail.reopened {
    .visit-detail__dropdown {
      & > div[role="tab"] .el-collapse-item__header {
        i {
          top: 0.25rem;
        }
        min-height: 6.75rem;
      }
    }
    .el-collapse-item__wrap {
      padding-top: 1rem;
    }
  }
  .visit-detail {

    &__dropdown {
      border-bottom: 1px solid $cortex;
      padding: 1rem 0;
      position: relative;

      & > div[role="tab"] .el-collapse-item__header {
        justify-content: space-between;
        position: relative;
        min-height: 3.75rem;
        padding-left: 1.75rem;
        background: transparent;
        color: $black;
        @include text-style('interface', 'medium', 'medium');
        transition: all .3s;
        border-bottom: none;

        &:hover,
        &.focusing:focus:not(:hover) {
          color: $gaba;
        }

        i {
          position: absolute;
          left: 0;
          top: 0.25rem;
          font-size: 1.25rem;
          transform: rotate(90deg);

          &.is-active {
            transform: rotate(-90deg);
          }

          @media screen and  (max-width: $breakpoint-mobile) {
            left: .5rem;
          }
        }
      }
    }
    .status-pill {
      display: inline-flex;
      &--reopened{
        margin-top: 0.275rem;
        background-color: #efe2ffd8;
        margin-bottom: 1rem;
      }
    }
    .forms-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        @include text-style('title', 'medium', 'regular');
        margin: 0;
        font-weight: 500;
      }
    }

    .visit-info {
      position: relative;
      min-height: 3.5rem;
    }

    /* offset the padding on the edit ghost button. */
    .visit-date {
      @include text-style('interface', 'small', 'regular');
      position: relative;
      margin: 0;

      span {
        display: inline-block;
      }

      &.editable {
        .start-date-options {
          padding: .5rem 0;
        }
      }
    }

    .visit-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: .5rem;
    }

    .start-date-options {
      display: inline-block;
      color: $dopamine;
      @include text-weight('medium');

      svg {
        display: none;
      }

      .dropdown__content {
        margin-top: .5rem;
        margin-left: .5rem;
      }
    }

    .start-date-picker {
      position: absolute;
      bottom: 0;
      right: 6.5rem;
      width: 1px;

      input {
        height: 1px;
        width: 1px;
        padding: 0;
        background: transparent;
        border: none;
      }

      .icon-wrapper {
        display: none;
      }
    }
  }

  #app .visit-info .el-button--ghost {
    @include text-weight('medium');
  }
</style>
