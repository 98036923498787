<template>
  <Modal
    v-if="displayModal"
    v-loading="isLoading"
    class="start-visit-modal"
    @close="$emit('close')"
  >
    <template v-slot:title>
      Start Visit
    </template>
    <template v-slot:content>
      <p>Before you begin this visit, please add a start date:</p>
      <ValidationProvider
        v-slot="{ errors }"
        rules="required"
        :detect-input="false"
      >
        <BfDatePicker
          v-model="visitStartDate"
          :error="errors[0]"
          class="datepicker"
          label="Visit Start Date"
        />
      </ValidationProvider>
    </template>
    <template v-slot:actions>
      <el-button
        type="primary"
        :disabled="!canStartVisit"
        @click="startNewVisit"
      >
        Start Visit
      </el-button>
      <el-button
        type="outline"
        @click="$emit('close')"
      >
        Cancel
      </el-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal/Modal'
import BfDatePicker from '@/components/BfDatePicker/BfDatePicker'
import START_VISIT_INSTANCE_V2 from '@/graphql/visits/StartVisitV2Mutation.graphql'
// import GET_PARTICIPANT_VISITS_QUERY from '@/graphql/participants/GetStudyParticipantVisitsQuery.graphql'
import GET_VISIT_WINDOW_V2_QUERY from '@/graphql/participants/GetParticipantVisitWindowV2Query.graphql'
import { formatDateForAPI } from '@/utils/date'
import { VisitStatus } from '@/utils/constants'
// import { pathEq } from 'ramda'
import { logError } from '@/utils/logging'

export default {
  components: {
    Modal,
    BfDatePicker
  },
  props: {
    displayModal: {
      type: Boolean,
      default: false
    },
    visit: {
      type: Object,
      required: true
    },
    routeToVisit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      visitStartDate: new Date() // set initial value as today
    }
  },
  computed: {
    canStartVisit() {
      return this.visitStartDate
    }
  },
  methods: {
    startNewVisit() {
      this.isLoading = true
      // No optimistic response here - we're only doing on-page updates while offline
      this.$apollo.mutate({
        mutation: START_VISIT_INSTANCE_V2,
        variables: {
          participantId: this.$route.params.participantId,
          protocolVersionId: this.visit.visitTemplate.protocolVersion.id,
          visitId: this.visit.visitId,
          status: VisitStatus.IN_PROGRESS,
          startDate: formatDateForAPI(this.visitStartDate)
        },
        refetchQueries: [{
          query: GET_VISIT_WINDOW_V2_QUERY,
          variables: {
            participantId: this.$route.params.participantId,
            visitWindowId: this.$route.params.visitWindowId,
            studyId: this.$route.params.studyId
          }
        }]
      }).then(response => {
        if (this.routeToVisit) {
          this.$router.push({
            name: 'visitManagement',
            params: {
              ...this.$route.params,
              visitInstanceId: response.data.startVisitInstance.id
            }
          })
        }
      }).catch(error => {
        logError(error, 'StartVisitModal.vue start visit mutation')
      }).finally(() => {
        this.isLoading = false
        this.$emit('close')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.start-visit-modal {
  /deep/ .field-wrap {
    width: 64%;
  }
  .datepicker {
    margin-top: 1rem;
  }
}
</style>
