<script>
import Modal from '@/components/Modal/Modal'
import BfAlert from '@/components/BfAlert/BfAlert'
import participant from '@/mixins/queries/participant'

export default {
  components: {
    Modal,
    BfAlert
  },
  mixins: [
    participant
  ],
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    getCohortName() {
      return this.participant.cohort?.name
    },

    getCohortSubgroupNames() {
      return this.participant.cohort?.subgroups
        .map(s => s.name)
        .sort()
        .join(', ')
    }
  },
  methods: {
    handleClickContinue() {
      try {
        this.$emit('start-visit-confirmed')
      } finally {
        this.$emit('close')
      }
    },

    handleClickChangeAssignments() {
      this.isLoading = true
      try {
        this.$router.push({
          name: 'participantProfile',
          params: {
            studyId: this.$route.params.studyId,
            siteId: this.$route.params.siteId,
            participantId: this.$route.params.participantId
          }
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<template>
  <Modal
    v-loading="isLoading"
    :closeable="true"
    fix-header-and-footer
    class="confirm-start-visit-modal"
    @close="$emit('close')"
  >
    <template v-slot:title>
      Confirm Cohort & Sub-group Assignment
    </template>
    <template v-slot:content>
      <div class="content-text">
        Confirm the assigned enrollment cohort and sub-group(s) are correct before continuing.
      </div>
      <div class="study-info">
        <div>Cohort</div>
        <div>{{ getCohortName }}</div>
      </div>
      <div class="study-info">
        <div>Subgroups</div>
        <div>{{ getCohortSubgroupNames }}</div>
      </div>
      <BfAlert
        type="warning"
      >
        <strong>Warning:</strong> The assignments listed above determine this participant's
        eligibility criteria and visit schedule. You will not be able to change these values
        once the Screening visit is started. If values need to be changed after starting the
        Screening visit, it will require you to re-enter data in EDC.
      </BfAlert>
    </template>
    <template v-slot:actions>
      <el-button
        type="primary"
        @click="handleClickContinue"
      >
        Continue
      </el-button>
      <el-button
        type="outline"
        @click="handleClickChangeAssignments"
      >
        Change Assignments
      </el-button>
    </template>
  </Modal>
</template>

<style lang="scss">
.confirm-start-visit-modal {
  .content-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 30px;
  }

  .study-info {
    margin-bottom: 30px;
    div:first-child {
      color: #70737c;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    div:last-child {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }
}
</style>
