import { render, staticRenderFns } from "./ReopenPreviousVisitModal.vue?vue&type=template&id=552a2885&scoped=true&"
import script from "./ReopenPreviousVisitModal.vue?vue&type=script&lang=js&"
export * from "./ReopenPreviousVisitModal.vue?vue&type=script&lang=js&"
import style0 from "./ReopenPreviousVisitModal.vue?vue&type=style&index=0&id=552a2885&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "552a2885",
  null
  
)

export default component.exports